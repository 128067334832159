import React, { useState } from "react";
import moment from "moment-timezone";
import Modal from "react-modal";
import styled from "styled-components";

import { Table, Th, Tr, Td } from "./elements/Table";
import Text from "./elements/Text";

import Editable from "./Editable";
import VerifyDeleteModal from "./VerifyDeleteModal";

import Check from "./icons/Check";
import Exclamation from "./icons/Exclamation";
import Trash from "./icons/Trash";
import { useAuth } from "../hooks/useAuth";
import Groups from "../constants/Groups";

const StatusIconWrapper = styled.span`
  padding-right: 5px;
`;

const DeleteIcon = styled.div`
  visibility: hidden;
  position: relative;
  float: right;
  left: 30px;
  top: -30px;
  height: 100%;
  background-color: white;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  color: red;

  ${Text}:hover + & {
    visibility: visible;
  }

  :hover {
    visibility: visible;
  }
`;

const GameIncidentUpdatesTable = ({ rootStore }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [updateToDelete, setUpdateToDelete] = useState({});
  const { veracityStore: store } = rootStore;
  const { username, isAuthenticated, groups } = useAuth();
  const canEdit = groups?.includes(Groups.ADMIN);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const handleDelete = () => {
    store.deleteIncidentUpdate(updateToDelete);
    toggleModal();
  };

  const incident = store?.singleIncident;
  const canDelete = canEdit && incident?.incidentUpdates?.length > 1;
  const update = store.updateIncidentUpdateName;
  const rows = incident.incidentUpdates.map((incidentUpdate) => {
    const { id, createdAt, status, description } = incidentUpdate;
    const timezone = process.env.REACT_APP_TIMEZONE;
    const createdAtMoment = moment(createdAt).tz(timezone);
    return (
      <Tr key={id}>
        <Td>
          <StatusIconWrapper>
            {status === "Open" ? (
              <Exclamation shape="circle" />
            ) : (
              <Check shape="circle" />
            )}
          </StatusIconWrapper>
        </Td>
        <Td>{createdAtMoment.format("LL")}</Td>
        <Td>{createdAtMoment.format("LT")}</Td>
        <Td>
          <div style={{ whiteSpace: "pre-wrap" }}>
            <Editable
              disabled={!canEdit}
              inputType="textarea"
              handleSave={update.bind(null, incidentUpdate)}
            >
              {description}
            </Editable>
            {canDelete && (
              <DeleteIcon
                onClick={() => {
                  toggleModal();
                  setUpdateToDelete(incidentUpdate);
                }}
              >
                <Trash />
              </DeleteIcon>
            )}
          </div>
        </Td>
      </Tr>
    );
  });

  return (
    <React.Fragment>
      <Table width="100%">
        <colgroup>
          <col width="2%" />
          <col width="10%" />
          <col width="10%" />
        </colgroup>
        <thead>
          <Tr>
            <Th />
            <Th>Date</Th>
            <Th>Updated At</Th>
            <Th textAlignment="left">Latest Update</Th>
          </Tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        ariaHideApp={false}
        className="deleteModal"
      >
        <VerifyDeleteModal
          message="Are you sure you want to remove this incident update?"
          toggleModal={toggleModal}
          handleDelete={handleDelete}
        />
      </Modal>
    </React.Fragment>
  );
};

export default GameIncidentUpdatesTable;
