import React from "react";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import rem from "../utils/rem";
import IncidentForm from "../components/IncidentForm";

const Wrapper = styled.div`
  margin: ${rem(30)};
  width: 1330px;
`;

class CreateNewIncident extends React.Component {
  render() {
    const { veracityStore: store } = this.props.rootStore;
    const { incidentType, gamePk, gameSlug, date, isSystemWide } =
      store.newIncidentMetadata;

    if (!isSystemWide && !(gamePk && date)) {
      console.log("Missing necessary new incident metadata");
      return <Redirect to="/" />;
    }

    return (
      <Wrapper>
        <h1>Data Quality Report - New Incident</h1>
        <h2>{isSystemWide ? "System Wide Incident" : gameSlug}</h2>
        <IncidentForm
          onSubmit={store.createIncident}
          afterSubmit={store.transitionAfterSave}
          incidentType={incidentType}
          gamePk={gamePk}
          isSystemWide={isSystemWide}
          date={date}
        />
      </Wrapper>
    );
  }
}

export default inject("rootStore")(observer(CreateNewIncident));
